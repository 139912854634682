import websiteFaster from '../assets/imgs/website-faster.png';
import websiteSafer from '../assets/imgs/website-safer1.png';
import websiteEasier from '../assets/imgs/website-easier.png';
import websiteFriend from '../assets/imgs/website-friend.png';
import faq1Img from '../assets/imgs/website-faq1.png';
import faq2Img from '../assets/imgs/website-faq2.png';
import faq3Img from '../assets/imgs/website-faq3.png';


export const page1 = [
  {
    img: websiteFaster,
    icon: '#iconzu5',
    title: 'FASTER',
    description: '1 ID, 1 mobile number, loan released in 2 hours to 24 hourss',
  },
  {
    img: websiteSafer,
    icon: '#iconzu2',
    title: 'SAFER',
    description: 'Encrypted personal information to ensure security, only used for review and certification',
  },
  {
    img: websiteEasier,
    icon: '#iconzu4',
    title: 'EASIER',
    description: 'Multiple sources (ML，RD Pawnshop),easy as buying a cola',
  },
  {
    img: websiteFriend,
    icon: '#iconzu3',
    title: 'MORE LIKE A FRIEND',
    description: 'A powerful team always ready to serve you in time of difficulty',
  },
];

export const page3 = [
  {
    img: faq1Img,
    title: 'What qualifications are required？',
    descriptions: [
      '1) A Phone number in use',
      '2) Valid government ID',
      '3) Stable income',
      '4) Age between 18-49 years old'
    ],
  },
  {
    img: faq2Img,
    title: 'How to apply a loan? ',
    descriptions: [
      '1) Install OKPeso and register with your own phone number.',
      '2) Fill out peso loan application form.',
      '3) Wait for cash loan approval.',
      '4) Claim your personal loan.'
    ],
  },
  {
    img: faq3Img,
    title: 'How to repay your cash loan?',
    descriptions: [
      '1)M Lhuillier:Visit M Lhuillier and fill the repay form.',
      '2)7-Eleven: Go to 7-Eleven and repay via CLiQQ machine or CLiQQ app.',
      '3)GCash,Coins: Open app, click Pay Bills to access Loans option to repay.',
      '4)Bank: Deposit money to our corporate bank account given in the app.',
      '5)ECPAY: Go to ECPAY, fill out needed information in Bills Payment Form, reimburse your personal loans and receive a payment confirmation receipt.'
    ]
  },
];
