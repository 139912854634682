import React from 'react';
import { HashRouter as Router, Link } from "react-router-dom";
import websiteFooterBg from '../assets/imgs/website-footerBg.png';

function Footer(props) {
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <img src={websiteFooterBg} className="footerBgImg" alt="footer" />
        <div className="footer-content">
          <div className="one-center">
            <h2>Codeblock Lending Inc.</h2>
            <div>
              <svg>
                <use xlinkHref="#iconmail" />
              </svg>
              <p>service@okpeso.com</p>
            </div>
            <div>
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi" />
              </svg>
              <p>Company Registration: NO.CS201913681</p>
            </div>
            <div>
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi" />
              </svg>
              <p>Certificate Of Authority: NO.3059</p>
            </div>
            {/* <div>
              <svg>
                <use xlinkHref="#iconaddress" />
              </svg>
              <p>2206, 88 Corporate Center, Sedeño,makati Metro Manila, Philippines</p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
    )
  }
  return (
    <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <img src={websiteFooterBg} className="footerBgImg" alt="footer" />
        <div className="footer-content">
          <div className="one-center mdr-card">
            <h2>Codeblock Lending Inc.</h2>
            <div>
              <svg>
                <use xlinkHref="#iconmail" />
              </svg>
              <p>service@okpeso.com</p>
            </div>
            <div>
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi" />
              </svg>
              <p>Company Registration: NO.CS201913681</p>
            </div>
            <div>
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi" />
              </svg>
              <p>Certificate Of Authority: NO.3059</p>
            </div>
            {/* <div>
              <svg>
                <use xlinkHref="#iconaddress" />
              </svg>
              <p>2206, 88 Corporate Center, Sedeño, makati Metro Manila</p>
            </div> */}
          </div>
          <div className="one-center">
            <h2 className="h2Marb">Follow Us</h2>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/RealOKPeso">
              <svg>
                <use xlinkHref="#iconfacebook"/>
              </svg>
              <span>Facebook</span>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/ok_peso">
              <svg>
                <use xlinkHref="#icontwitter"/>
              </svg>
              <span>Twitter</span>
            </a>
            <a href="#/">
              <svg>
                <use xlinkHref="#iconyoutube"/>
              </svg>
              <span>Youtube</span>
            </a>
          </div>
          <div className="one-center">
            <h2 className="h2Marb">Regulations</h2>
            <Router>
              <Link to="/creditAgreement" target="_blank" rel="noopener noreferrer" >
                《OKPeso Loan Agreement》
              </Link>
              <Link to="/privacyAgreement" target="_blank" rel="noopener noreferrer" >
                《Privacy Agreement》
              </Link>
              <Link to="/serviceAgreement" target="_blank" rel="noopener noreferrer" >
                《Service Agreement》
              </Link>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2019 | OKPeso(OKApp, OKPS) | All Rights Reserved
      </div>
    </footer>
  );
}


export default Footer;
